import axios from "../http";
import { BASE } from "../index";
export const URL = BASE.PRO1;

/**
 * 供货商管理-配置服务手续费
 * @param {*} params
 * @returns
 */
export function ajaxPostAddCostConfig(params = {}) {
  return axios.post(`${URL}/supplier/platform/fee/save`, params);
}

/**
 * 打款管理-保定银行批量打款
 * @param {*} params
 * @returns
 */
export function postBdBankBatchPay(params = {}) {
  return axios.post(`${URL}/supplier/bd/bank/batchPay`, params);
}

/**
 * 城市仓打款管理-保定银行批量打款
 * @param {*} params
 * @returns
 */
export function postStoreLogisticsBdBankBatchPay(params = {}) {
  return axios.post(`${URL}/store/logistics/bd/bank/batchPay`, params);
}
